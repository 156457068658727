import React, { createContext, useState, useContext, useEffect } from 'react';

interface ResultInputs {
    name: string;
    area: string;
    dimension0: string;
    dimension1: string;
    funtions: string[];
    type: string;
    select?: string;
    evaporator?: {
        btuCode?: number;
        capacity?: string;
        name?: string;
    };
    isRequiredErr: boolean;
    iframe_url?: string;
}

interface UserResult {
    btuTotal: number;
    imovelType: string;
    result: ResultInputs[]
}

type ContextType = {
    userResult: UserResult;
    setUserResult: (value: UserResult) => void;
};

const ContextApp = createContext<ContextType>({
    userResult: {} as UserResult,
    setUserResult: (Value: UserResult) => { },
});

export type { UserResult, ResultInputs }

const ProviderResult: React.FC = ({ children }) => {
    const [userResult, setUserResult] = useState<UserResult>({} as UserResult)
    const loadData = () => {
        const value = localStorage.getItem('@userResult')
        if (value) {
            setUserResult(JSON.parse(value))
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    return (
        <ContextApp.Provider value={{
            userResult, setUserResult
        }}>
            {children}
        </ContextApp.Provider>
    );
}
export default ProviderResult;

// export function useResult() {
//     const infoUser: ContextType = useContext(ContextApp);
//     const { userResult, setUserResult } = infoUser;
//     return { userResult, setUserResult };
// }

