import React from 'react';
import styles from './styles.module.scss'
import equip from '../../assets/equipamentos.png'
import quarto from '../../assets/quarto.png'
import sala from '../../assets/sala.png'
import Container from 'react-bootstrap/Container'
import { Row, Col, Media } from 'react-bootstrap'
import ButtonsResults from '../ButtonsResults';
import condesadordas18 from '../../assets/condesadoras/18/A2UW18GFA_02.png'
import condesadordas24 from '../../assets/condesadoras/24/A3UW24GFA2_02.png'
import condesadordas30_36 from '../../assets/condesadoras/30_36/A4UW30GFA2_01_A5UW36GFA2_01.png'
import {UserResult} from '../../context/result'

import Artcool from '../../assets/evaporadoras/Artcool/005.jpg'
import ArtcoolGalery from '../../assets/evaporadoras/ArtCoolGallery/026.jpg'
import Cassete1via from '../../assets/evaporadoras/Cassete1Via/006.jpg'
import Cassete4vias from '../../assets/evaporadoras/Cassete4vias/006.jpg'
import Hiwall from '../../assets/evaporadoras/Hiwall/001.jpg'

interface Con {
    name: string;
    btuCode: number;
    kW: number;
    capacity: string;
}
interface Props {
    data: Con[];
    evaporadoras: UserResult
}


const Results: React.FC<Props> = (props) => {
    const getImg = (m: number)=> {
        if(m== 18000){
         return   condesadordas18
        }
        if(m == 24000){
            return condesadordas24
        }
        if(m >= 30000 && m <=36000){
          return  condesadordas30_36
        }
        return condesadordas30_36
    }

    const getImgEvaporadoras = (name?: string) =>{
        if(name === 'Artcool Espelhado') return Artcool;

        if(name === 'Artcool Galery') return ArtcoolGalery;

        if(name === 'Cassete 1 via') return Cassete1via;

        if(name === 'Cassete 4 vias') return Cassete4vias;

        if(name === 'Hi Wall') return Hiwall;

        return ''
    }
    return (
        <>
            <Container>
                <Row className="align-items-center">
                    <br />
                    <br />
                    <h1 style={{ width: '100%', textAlign: 'center' }} className={styles.title_result}>Resultado:</h1>

                    {props.data.map((e: Con) => {
                        return (
                            <>
                                <Col lg={6}>
                                    <Row>
                                        <Col md={6} sm={12} className="d-flex justify-content-center">
                                            <div style={{ backgroundImage: `url(${getImg(e.btuCode)})` }} className={styles.img_bg}></div>
                                        </Col>

                                        <Col md={6} sm={12}>
                                            <p className={styles.title_product}>{e.name +' - ' + e.btuCode + 'BTUs'}</p>
                                            {/* {props.evaporadoras.result.map((r)=>{
                                                return (<>
                                                <br/>
                                                <small className={styles.subtitle_product}>{ r.name + ' - ' +r.evaporator?.name + ' - ' + r.evaporator?.btuCode + 'BTUs '}</small>
                                                <br/>
                                                </>)
                                            })} */}
                                            <p><small className={styles.subtitle_product}>*Sujeito a disponibilidade de estoque</small></p>

                                            <ButtonsResults />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col style={{minWidth: 400,maxWidth:450, padding: 10 }} lg={6} className="ml-auto mt-4 mt-md-0">

                                    <Row style={{overflow:'hidden', overflowX:'scroll', flexDirection:'row', flexWrap: 'nowrap'}} className="align-items-center justify-content-center">
                                        {props.evaporadoras.result.map(r=>{
                                            return(
                                                <div className="ml-lg-auto mr-1">
                                                <h2 style={{textAlign:'center'}} className={styles.title_ambience}>{r.evaporator?.name}<br/>{r.evaporator?.btuCode+'BTUs'}</h2>
                                                <Media>
                                                    <img
                                                        width={170}
                                                        height={200}
                                                        src={getImgEvaporadoras(r.evaporator?.name)}
                                                        alt="imagem quarto"
                                                    />
                                                </Media>
                                            </div>
                                            )
                                        })}
                                    </Row>
                                </Col>
                                <div style={{padding: 15}}/>
                                <hr style={{width:'100%'}}/>
                                <div style={{padding: 10}}/>
                            </>
                        )
                    })}
                </Row>
                <hr />
            </Container>
        </>
    )
}

export default Results;