import React from 'react';
import { useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import house from '../../assets/house7.png'
import company from '../../assets/company8.png'
import Container from 'react-bootstrap/Container'

const Home: React.FC = () => {
    const history = useHistory()
    const handleNextPage = (imovelType: string) => {
        localStorage.clear()
        history.push(`/lg/Simulator/${imovelType}`)
        window.scrollTo(0,0)
    }
    return (
        <>
            <Container>
                <h1 className={styles.projectName}>Projeto</h1>
                <p className={styles.text}>
                    Escolha os modelos LG inverter e descubra qual a melhor combinação para o seu projeto.
                </p>
                <p className={styles.sub_text}>
                    <b>Selecione o ambiente desejado:</b>
                </p>
            </Container>

            <Container fluid className="p-0" >
                <div className={styles.optionsView}>
                    <div style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fhouse7.png?alt=media&token=a1a86e03-7b5e-426e-a1a2-5b98880968b4)` }} className={styles.metade}>
                        <div onClick={() => handleNextPage('house')} className={styles.buttons}>
                            <span>RESIDENCIAL</span>
                        </div>
                    </div>
                    <div style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fcompany8.png?alt=media&token=846882f6-9ffb-4cb2-8f8d-8a3c96108e3e)` }} className={styles.metade}>
                        <div onClick={() => handleNextPage('company')} className={styles.buttons}>
                            <span>COMERCIAL</span>
                        </div>
                    </div>
                </div>
                
            </Container>
        </>
    )
}
export default Home;