import React from 'react';
import { Media } from 'react-bootstrap';
import styles from './styles.module.scss'

interface Props {
    logo: any;
    name: string;
    link: string;
}
const Cards: React.FC<Props> = ({name, link, logo}) => {
    return(
        <>

            <div className={styles.card_partners}>
                
                    <div className={styles.logo}>
                        <Media>
                        <img
                            src= {logo}
                            alt="logo empresa"
                        />
                        </Media>
                    </div>

                    <div className={styles.container_name}>
                        <p className={styles.name_partner}>
                            {name}
                        </p>
                    </div>
                    
                    <div className={styles.container_button}>
                        <a href={link} target="_blank" className={styles.button_onlineBuy}>Compre Online</a>
                    </div>
                </div>

        </>
    )
}
export default Cards;