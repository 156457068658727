import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import logo from '../../assets/logo.png'
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap'
import { useScreen } from '../../context/screen'
//@ts-ignore
import MenuIcon from '../../assets/menua.png'
const Header: React.FC = () => {
    const { isMobile } = useScreen()
    const history = useHistory()

    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <>
            {isMobile ?
                <div className='d-flex text-center align-items-center justify-content-center'>
                    <div className={styles.contConMOb}>
                        <div onClick={() => setIsOpen(true)} style={{ backgroundImage: `url(${MenuIcon})`, backgroundSize: 'cover', width: 32, height: 32, cursor: 'pointer' }} />
                        <img className={styles.logo} src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Flogo.png?alt=media&token=3361b2d8-d251-4108-aa3a-b13c941f6a4f" alt="logo lg" />
                        <div style={{ width: 32, height: 32 }} />
                    </div>
                </div>
                :
                <Container>
                    <Row className="align-items-center justify-content-lg-between justify-content-center">
                        <Col md={4} className="d-flex align-items-center justify-content-md-between justify-content-center">
                            <img style={{ cursor: 'pointer' }} onClick={() => history.push('/lg')} className={styles.logo} src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Flogo.png?alt=media&token=3361b2d8-d251-4108-aa3a-b13c941f6a4f" alt="logo lg" />
                        </Col>

                        <Col md={8}>
                            <p className={styles.saibamais}>
                                Saiba mais sobre o sistema do ar condicionado Multi Split da LG. <b><span><a href="https://www.lg.com/br/business/multi-split-ar-condicionado" target="_blank">Clique aqui</a></span></b>
                            </p>
                        </Col>
                    </Row>
                </Container>}
            <hr className={styles['hr']} />




            <div style={{ width: Boolean(isOpen && isMobile) ? 250 : 0 }} className={styles.sidenav}>
                <div className={styles.rowExitView2}>
                    <span onClick={() => setIsOpen(false)}>X</span>
                </div>

                <br />
            </div>
        </>
    )
}

export default Header;