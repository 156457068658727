import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Container from '../componets/Container'
import Home from '../pages/Home'
import Products from '../pages/Products'
import Simulator from '../pages/Simulator'
import Partners from '../pages/Partners'

const RouterMain: React.FC = () => {

    return (
        <BrowserRouter>
            <Container>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/lg/Products" exact component={Products} />
                    <Route path="/lg/Partners" exact component={Partners} />
                    <Route path="/lg/Simulator/:imovelType" exact component={Simulator} />
                    <Route component={Home} />
         
                    
                </Switch>
            </Container>
        </BrowserRouter>
    )
}
export default RouterMain;