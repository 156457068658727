import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { States, states } from "../../mocks/states";
//@ts-ignore
import Carousel from "react-simply-carousel";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  Media,
  Dropdown,
  DropdownButton,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import chevron from "../../assets/chevron_white_right.svg";
import produce from "immer";
//@ts-ignore
import MenuIcon from "../../assets/menu-2.png";
//@ts-ignore
import ViewIcon from "../../assets/view.png";
import { useScreen } from "../../context/screen";
import { UserResult, ResultInputs } from "../../context/result";
import { useHistory, useParams } from "react-router-dom";

import Artcool1 from "../../assets/evaporadoras/Artcool/001.jpg";
import Artcool2 from "../../assets/evaporadoras/Artcool/002.jpg";
import Artcool3 from "../../assets/evaporadoras/Artcool/003.jpg";
import Artcool4 from "../../assets/evaporadoras/Artcool/004.jpg";
import Artcool5 from "../../assets/evaporadoras/Artcool/005.jpg";
import Artcool6 from "../../assets/evaporadoras/Artcool/006.jpg";
import Artcool7 from "../../assets/evaporadoras/Artcool/007.jpg";
import Artcool8 from "../../assets/evaporadoras/Artcool/008.jpg";
import Artcool9 from "../../assets/evaporadoras/Artcool/009.jpg";
import Artcool10 from "../../assets/evaporadoras/Artcool/010.jpg";
import Artcool11 from "../../assets/evaporadoras/Artcool/011.jpg";
import Artcool12 from "../../assets/evaporadoras/Artcool/012.jpg";
import Artcool13 from "../../assets/evaporadoras/Artcool/013.jpg";
import Artcool14 from "../../assets/evaporadoras/Artcool/014.jpg";
import Artcool15 from "../../assets/evaporadoras/Artcool/015.jpg";
import Artcool16 from "../../assets/evaporadoras/Artcool/016.jpg";

import ArtcoolGalery1 from "../../assets/evaporadoras/ArtCoolGallery/001.jpg";
import ArtcoolGalery2 from "../../assets/evaporadoras/ArtCoolGallery/002.jpg";
import ArtcoolGalery3 from "../../assets/evaporadoras/ArtCoolGallery/003.jpg";
import ArtcoolGalery4 from "../../assets/evaporadoras/ArtCoolGallery/004.jpg";
import ArtcoolGalery5 from "../../assets/evaporadoras/ArtCoolGallery/005.jpg";
import ArtcoolGalery6 from "../../assets/evaporadoras/ArtCoolGallery/006.jpg";
import ArtcoolGalery7 from "../../assets/evaporadoras/ArtCoolGallery/007.jpg";
import ArtcoolGalery8 from "../../assets/evaporadoras/ArtCoolGallery/008.jpg";
import ArtcoolGalery9 from "../../assets/evaporadoras/ArtCoolGallery/009.jpg";
import ArtcoolGalery10 from "../../assets/evaporadoras/ArtCoolGallery/010.jpg";
import ArtcoolGalery11 from "../../assets/evaporadoras/ArtCoolGallery/011.jpg";
import ArtcoolGalery12 from "../../assets/evaporadoras/ArtCoolGallery/012.jpg";
import ArtcoolGalery13 from "../../assets/evaporadoras/ArtCoolGallery/013.jpg";
import ArtcoolGalery14 from "../../assets/evaporadoras/ArtCoolGallery/014.jpg";
import ArtcoolGalery15 from "../../assets/evaporadoras/ArtCoolGallery/015.jpg";
import ArtcoolGalery16 from "../../assets/evaporadoras/ArtCoolGallery/016.jpg";
import ArtcoolGalery17 from "../../assets/evaporadoras/ArtCoolGallery/017.jpg";
import ArtcoolGalery18 from "../../assets/evaporadoras/ArtCoolGallery/018.jpg";
import ArtcoolGalery19 from "../../assets/evaporadoras/ArtCoolGallery/019.jpg";
import ArtcoolGalery20 from "../../assets/evaporadoras/ArtCoolGallery/020.jpg";
import ArtcoolGalery21 from "../../assets/evaporadoras/ArtCoolGallery/021.jpg";
import ArtcoolGalery22 from "../../assets/evaporadoras/ArtCoolGallery/022.jpg";
import ArtcoolGalery23 from "../../assets/evaporadoras/ArtCoolGallery/023.jpg";
import ArtcoolGalery24 from "../../assets/evaporadoras/ArtCoolGallery/024.jpg";
import ArtcoolGalery25 from "../../assets/evaporadoras/ArtCoolGallery/025.jpg";
import ArtcoolGalery26 from "../../assets/evaporadoras/ArtCoolGallery/026.jpg";
import ArtcoolGalery27 from "../../assets/evaporadoras/ArtCoolGallery/027.jpg";
import ArtcoolGalery28 from "../../assets/evaporadoras/ArtCoolGallery/028.jpg";
import ArtcoolGalery29 from "../../assets/evaporadoras/ArtCoolGallery/029.jpg";
import ArtcoolGalery30 from "../../assets/evaporadoras/ArtCoolGallery/030.jpg";

import Cassete1via1 from "../../assets/evaporadoras/Cassete1Via/001.jpg";
import Cassete1via2 from "../../assets/evaporadoras/Cassete1Via/002.jpg";
import Cassete1via3 from "../../assets/evaporadoras/Cassete1Via/003.jpg";
import Cassete1via4 from "../../assets/evaporadoras/Cassete1Via/004.jpg";
import Cassete1via5 from "../../assets/evaporadoras/Cassete1Via/005.jpg";
import Cassete1via6 from "../../assets/evaporadoras/Cassete1Via/006.jpg";

import Cassete4via1 from "../../assets/evaporadoras/Cassete4vias/001.jpg";
import Cassete4via2 from "../../assets/evaporadoras/Cassete4vias/002.jpg";
import Cassete4via3 from "../../assets/evaporadoras/Cassete4vias/003.jpg";
import Cassete4via4 from "../../assets/evaporadoras/Cassete4vias/004.jpg";
import Cassete4via5 from "../../assets/evaporadoras/Cassete4vias/005.jpg";
import Cassete4via6 from "../../assets/evaporadoras/Cassete4vias/006.jpg";

import HiWall1 from "../../assets/evaporadoras/Hiwall/001.jpg";
import HiWall2 from "../../assets/evaporadoras/Hiwall/002.jpg";
import HiWall3 from "../../assets/evaporadoras/Hiwall/003.jpg";
import HiWall4 from "../../assets/evaporadoras/Hiwall/004.jpg";
import HiWall5 from "../../assets/evaporadoras/Hiwall/005.jpg";

interface OptionArraySelect {
  btuCode?: number;
  name: string;
  img: string[];
}

const Simulator: React.FC = () => {
  const [showIframe, setShowIframe] = React.useState(false);
  const [estados, setEstados] = useState<string>("AC");
  const history = useHistory();
  const { imovelType } = useParams<{ imovelType: string }>();
  const [ambiente, setAmbiente] = useState<number>(0);
  const [result, setResult] = useState<ResultInputs[]>([]);
  const [indexNumberModal, setIndexNumberModal] = useState<number>(0);
  const [indexNumberModalVisible, setIndexNumberModalVisible] =
    useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const { isMobile } = useScreen();

  const getBTU = (m: number) => {
    if (imovelType === "house") {
      if (m <= 9) return 7000;
      if (m <= 12) return 9000;
      if (m <= 15) return 12000;
      if (m <= 20) return 16000;
      if (m <= 25) return 20000;
      if (m <= 30) return 24000;
      if (m <= 35) return 28000;
      if (m <= 40) return 32000;
      if (m <= 45) return 36000;
      if (m <= 50) return 40000;
      if (m <= 60) return 48000;
      return 56000;
    } else {
      if (m <= 9) return 7000;
      if (m <= 12) return 7000;
      if (m <= 15) return 9000;
      if (m <= 20) return 12000;
      if (m <= 25) return 15000;
      if (m <= 30) return 18000;
      if (m <= 35) return 21000;
      if (m <= 40) return 24000;
      if (m <= 45) return 27000;
      if (m <= 50) return 30000;
      if (m <= 60) return 36000;
      return 42000;
    }
  };

  const optionsSelection = [
    {
      name: "Artcool Espelhado",
      iframe_url: "https://www.qrxp.app/clientes/lg/artcool/",
      img: [
        // Artcool3,
        // Artcool4,
        Artcool5,
        // Artcool6,
        // Artcool7,
        // Artcool8,
        //Artcool9,
        // Artcool10,
        //Artcool11,
        // Artcool12,
        // Artcool13,
        // Artcool14,
        // Artcool15,
        // Artcool16
      ],
    },
    {
      name: "Artcool Galery",
      iframe_url: "https://www.qrxp.app/clientes/lg/art-cool-galley/",
      img: [
        // ArtcoolGalery2,
        // ArtcoolGalery3,
        // ArtcoolGalery4,
        // ArtcoolGalery5,
        // ArtcoolGalery6,
        // ArtcoolGalery7,
        // ArtcoolGalery8,
        // ArtcoolGalery9,
        // ArtcoolGalery10,
        // ArtcoolGalery11,
        // ArtcoolGalery12,
        // ArtcoolGalery13,
        // ArtcoolGalery14,
        // ArtcoolGalery15,
        // ArtcoolGalery16,
        // ArtcoolGalery17,
        // ArtcoolGalery18,
        // ArtcoolGalery19,
        // ArtcoolGalery20,
        // ArtcoolGalery21,
        // ArtcoolGalery22,
        // ArtcoolGalery23,
        // ArtcoolGalery24,
        // ArtcoolGalery25,
        ArtcoolGalery26,
        // ArtcoolGalery27,
        // ArtcoolGalery28,
        // ArtcoolGalery29,
        // ArtcoolGalery30
      ],
    },
    {
      name: "Cassete 1 via",
      img: [
        // Cassete1via1,
        // Cassete1via2,
        //  Cassete1via3,
        // Cassete1via4,
        // Cassete1via5,
        Cassete1via6,
      ],
    },
    {
      name: "Cassete 4 vias",
      img: [
        // Cassete4via1,
        //        Cassete4via2,
        // Cassete4via3,
        // Cassete4via4,
        // Cassete4via5,
        Cassete4via6,
      ],
    },
    {
      name: "Hi Wall",
      iframe_url: "https://www.qrxp.app/clientes/lg/hiwall/",
      img: [
        HiWall1,
        // HiWall2,
        // HiWall3,
        // HiWall4,
        // HiWall5
      ],
    },
  ];

  useEffect(() => {
    const values = localStorage.getItem("@userResult");
    if (values) {
      const value: UserResult = JSON.parse(values);
      if (history.location.pathname.search(value.imovelType) >= 0) {
        setResult(value.result);
        setAmbiente(value.result.length);
      }
    }
    console.log(result);
  }, []);

  const getValidation = () => {
    let formIsValid = true;
    console.log(result);
    result.forEach((e) => {
      if (e.name.length === 0) {
        formIsValid = false;
      }
      if (e.select?.length === 0) {
        formIsValid = false;
      }
      if (e.select === "Selecione") {
        formIsValid = false;
      }
      if (
        e.type === "LxP" &&
        Boolean(e.dimension0.length === 0 || e.dimension1.length === 0)
      ) {
        formIsValid = false;
      }
      if (e.type !== "LxP" && e.area.length === 0) {
        formIsValid = false;
      }
    });

    return formIsValid;
  };
  const onAmbience = (selectNumber: number): void => {
    console.log("sss", selectNumber);
    setAmbiente(selectNumber);
    let i = 0;
    var draftState = [];
    for (i; i < selectNumber; i++) {
      draftState.push({
        area: "",
        dimension0: "",
        dimension1: "",
        funtions: [],
        isRequiredErr: false,
        select: "",
        name: "",
        type: "LxP",
        iframe_url: "",
      });
    }
    setResult(draftState);
  };

  const onCheckFunction = (value: string, item: ResultInputs): boolean => {
    const isSelect = item.funtions.indexOf(value) >= 0 ? true : false;
    return isSelect;
  };

  const handleCheckValuFunction = async (
    value: string,
    item: ResultInputs,
    index: number
  ) => {
    // await setResult([])

    const isExistValue = item.funtions.indexOf(value) >= 0 ? true : false;
    if (!isExistValue) {
      const newItem = [...item.funtions, value];
      const newResult = produce(result, (draftState) => {
        draftState[index].funtions = newItem;
        draftState[index].select = "Selecione";
        draftState[index].isRequiredErr = false;
      });
      console.log(newItem);
      setResult(newResult);
    } else {
      const newItem = item.funtions.filter((res) => res !== value);
      const newResult = produce(result, (draftState) => {
        draftState[index].funtions = newItem;
        draftState[index].select = "Selecione";
        draftState[index].isRequiredErr = false;
      });
      console.log(newItem);
      setResult(newResult);
    }
  };

  function handleTypeFromIndex(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    console.log(event.target.value, index, result[index]);
    const newResult = [...result];
    newResult[index] = { ...newResult[index], type: event.target.value };
    setResult(newResult);
  }

  const handleNextPage = (imovelType: string) => {
    let btus: number = 0;
    result.forEach((e) => {
      btus = btus + Number(e.evaporator?.btuCode);
    });
    const data: UserResult = {
      btuTotal: btus,
      imovelType: imovelType,
      result: result,
    };
    localStorage.setItem("@userResult", JSON.stringify(data));
    history.push(`/lg/Products`);
    window.scrollTo(0, 0);
  };

  const onOpenModal = (index: number) => {
    const e = result[index];
    if (e.name.length === 0) {
      return;
    }
    if (e.type !== "LxP" && e.area.length === 0 && e.area !== "0") {
      return;
    }
    setIndexNumberModal(index);
    setIndexNumberModalVisible(true);
  };

  const MODAL_CONTENT = () => {
    return (
      <>
        <br />
        <span
          onClick={() => setIndexNumberModalVisible(false)}
          style={{
            color: "white",
            fontSize: 28,
            width: "100%",
            textAlign: "left",
            cursor: "pointer",
          }}
          className="fas fa-times"
        />

        {optionsSelection.map((res) => {
          return (
            <>
              <br />
              <br />
              <br />
              <div className={styles.rowbuttons}>
                <div style={{ cursor: "none" }} className={styles.buttinss}>
                  <span>{res.name.replace("0", "*")}</span>
                </div>
                {/* <span style={{ color: 'white', fontSize: 22, paddingLeft: 10 }}>{`>`}</span> */}
              </div>
              <br />
              <span className={styles.carrseallView}>
                {res.img.map((das) => {
                  return (
                    <>
                      <div
                        style={{
                          backgroundImage: `url(${das})`,
                          backgroundSize: "cover",
                        }}
                        className={styles.divConnt}
                      >
                        {/* <div className={styles.hoverModal}>
                          <div className={styles.backViewie}>
                            <span className={styles.nameModalHover}>{res.name.replace('0', '*')}</span>
                          </div>
                          <div onClick={() => {
                            const val: string = res.name
                            const newList = produce(result, draftState => {
                              draftState[indexNumberModal].isRequiredErr = val.search('0') >= 0 ? true : false
                              draftState[indexNumberModal].select = val.replace('0', '*')
                              draftState[indexNumberModal].evaporator = {
                                btuCode: getBTU(Number(draftState[indexNumberModal].area)),
                           
                                name: res.name
                              }
                            })
                            setResult(newList)
                            setIndexNumberModalVisible(false)
                          }} className={styles.buttonsnsVi}>
                            <span>Selecionar</span>
                          </div>
                        </div> */}
                      </div>
                    </>
                  );
                })}
              </span>
              <br />
              <div
                onClick={() => {
                  const val: string = res.name;
                  const newList = produce(result, (draftState) => {
                    draftState[indexNumberModal].isRequiredErr =
                      val.search("0") >= 0 ? true : false;
                    draftState[indexNumberModal].select = val.replace("0", "*");
                    draftState[indexNumberModal].evaporator = {
                      btuCode: getBTU(
                        Number(draftState[indexNumberModal].area)
                      ),

                      name: res.name,
                    };
                    draftState[indexNumberModal].iframe_url =
                      res.iframe_url || "";
                  });
                  setResult(newList);
                  setIndexNumberModalVisible(false);
                }}
                style={{ background: "linear-gradient(#00c1fc, #005eee)" }}
                className={styles.buttinss}
              >
                <span>Selecione</span>
              </div>
              <br />
            </>
          );
        })}
        {/* result[indexNumberModal]?.funtions.length !== 0 ? selectOptions.map((ress: OptionArraySelect, i: number) => {
          return (
            <>
              <br />
              <br />
              <br />
              <div className={styles.rowbuttons}>
                <div style={{ cursor: 'none' }} className={styles.buttinss}>
                  <span>{ress.name.replace('0', '*')}</span>
                </div>
                <span style={{ color: 'white', fontSize: 22 }}>{`>`}</span>
              </div>
              <br />
              <span className={styles.carrseallView}>
                {ress.capacity.map((e, indexx) => {
                  return (
                    <>
                      <div style={{ backgroundImage: `url(${ress.img[indexx]})`, backgroundSize: 'cover' }} className={styles.divConnt} >
                        <div className={styles.hoverModal}>
                          <div className={styles.backViewie}>
                            <span className={styles.nameModalHover}>{ress.name.replace('0', '*')}</span>
                            <span className={styles.capacityModalHover}>{ress.capacity[indexx]}</span>
                            <span className={styles.btuModalHover}>{ress.btuCode[indexx]} BTUs</span>
                          </div>
                          <div onClick={() => {
                            const val: string = ress.name + ' ' + ress.capacity[indexx] + ' ' + ' ' + ress.btuCode[indexx] + ' BTUs'

                            const newList = produce(result, draftState => {
                              draftState[indexNumberModal].isRequiredErr = val.search('0') >= 0 ? true : false
                              draftState[indexNumberModal].select = val.replace('0', '*')
                              draftState[indexNumberModal].evaporator = {
                                btuCode: ress.btuCode[indexx],
                                capacity: ress.capacity[indexx],
                                name: ress.name
                              }
                            })
                            setResult(newList)
                            setIndexNumberModalVisible(false)

                          }} className={styles.buttonsnsVi}>
                            <span>Selecionar</span>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: 5 }} />
                    </>
                  )
                })}
              </span>
            </>
          )
        }) : defaultSelectOptions.map((ress: OptionArraySelect, i: number) => {

          return (
            <>
              <br />
              <br />
              <br />
              <div className={styles.rowbuttons}>
                <div style={{ cursor: 'none' }} className={styles.buttinss}>
                  <span className={styles.textt}>{ress.name.replace('0', '*')}</span>
                </div>
                {/* <span style={{ color: 'white', fontSize: 22 }}>{`>`}</span> 
              </div>
              <br />
              <span className={styles.carrseallView}>

                {ress.capacity.map((r, indexx) => {
                  return (
                    <>
                      <div style={{ backgroundImage: `url(${ress.img[indexx]})`, backgroundSize: 'cover' }} className={styles.divConnt} >
                        <div style={isMobile ? { display: 'flex' } : {}} className={styles.hoverModal}>
                          <div style={isMobile ? { maxHeight: '34%', display: 'flex' } : {}} className={styles.backViewie}>
                            <span style={isMobile ? { display: 'flex' } : {}} className={styles.nameModalHover}>{ress.name.replace('0', '*')}</span>
                            <span style={isMobile ? { display: 'flex' } : {}} className={styles.capacityModalHover}>{ress.capacity[indexx]}</span>
                            <span style={isMobile ? { display: 'flex' } : {}} className={styles.btuModalHover}>{ress.btuCode[indexx]} BTUs</span>
                          </div>
                          <div style={isMobile ? { display: 'flex' } : {}} onClick={() => {
                            const val: string = ress.name + ' ' + ress.capacity[indexx] + ' ' + ress.btuCode[indexx] + ' BTUs'

                            const newList = produce(result, draftState => {
                              draftState[indexNumberModal].isRequiredErr = val.search('0') >= 0 ? true : false
                              draftState[indexNumberModal].select = val.replace('0', '*')
                              draftState[indexNumberModal].evaporator = {
                                btuCode: ress.btuCode[indexx],
                                capacity: ress.capacity[indexx],
                                name: ress.name
                              }
                            })
                            setResult(newList)
                            setIndexNumberModalVisible(false)

                          }} className={styles.buttonsnsVi}>
                            <span>Selecionar</span>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: 5 }} />
                    </>
                  )
                })} 
              </span>
            </>
          )
        })
      */}
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "flex-start",
        }}
        className="d-flex text-center align-items-center "
      >
        <Container>
          <div className={styles.view}>
            <h1 className={styles.textSimulator}>Simulador de Capacidade</h1>
            <p className={styles.text}>
              Descubra o ar-condicionado ideal para você e quanto poderá
              economizar.
            </p>
            <hr className={styles.hr_simulator} />
          </div>
        </Container>

        <Container className="align-items-center justify-content-sm-center justify-content-lg-start">
          <div className={styles.rowView}>
            <div className={styles.rows}>
              <label htmlFor="state" className={styles.textS}>
                Selecione o Estado
              </label>
              <div style={{ padding: "4px" }} />
              {/* <select
              value={estados}
              onChange={(e) => setEstados(e.target.value)}
              name="state"
              id="state"
            >
              {states.map((res: States) => {
                return <option value={res.sigla}>{res.sigla}</option>;
              })}
            </select> */}

              <DropdownButton
                id="dropdown-item-button"
                title={estados || "selecione"}
              >
                <div className={styles.drop}>
                  {states.map((res: States) => {
                    return (
                      <Dropdown.Item
                        onClick={() => setEstados(res.sigla)}
                        as="button"
                      >
                        {res.sigla}
                      </Dropdown.Item>
                    );
                  })}
                </div>
              </DropdownButton>
            </div>

            <div className={styles.rows}>
              <label htmlFor="n" className={styles.textS}>
                Número de ambientes
              </label>
              <div style={{ padding: "4px" }} className={styles.button_ambi} />
              {/*  <select
              value={String(ambiente)}
              onChange={(e) => onAmbience(Number(e.target.value))}
              name="n"
              id="n"
            >
              <option value="">Selecione</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select> */}

              <DropdownButton
                id="dropdown-item-button"
                title={ambiente !== 0 ? ambiente : "Selecione"}
              >
                <Dropdown.Item onClick={() => onAmbience(2)} as="button">
                  2
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAmbience(3)} as="button">
                  3
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAmbience(4)} as="button">
                  4
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onAmbience(5)} as="button">
                  5
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </Container>

        <div className={styles.body_view}>
          <div className={styles.view}>
            {result.map((res: ResultInputs, index: number) => {
              return (
                <>
                  <div className={styles.viewItems}>
                    <Container>
                      <Row className="align-items-center">
                        <Col md="auto">
                          <div className={styles.bolas}>
                            <span>{index + 1}</span>
                          </div>
                        </Col>
                        <Col md="auto" className="mt-3 mt-lg-0">
                          <p className=" pb-2">Nome do ambiente</p>
                          <input
                            value={result[index]?.name}
                            onChange={(e) => {
                              const newResult = produce(
                                result,
                                (draftState) => {
                                  draftState[index].name = e.target.value;
                                }
                              );
                              setResult(newResult);
                            }}
                            style={
                              isMobile
                                ? { width: "90%", height: 40, paddingLeft: 7 }
                                : { paddingLeft: 7 }
                            }
                            id="ambi"
                            name="ambi"
                          />
                        </Col>

                        <Col md="auto" className="mt-4 mt-lg-0">
                          <div className="mt-4 mt-md-0">
                            <label htmlFor="area_ambi">
                              <div className={styles.columns3}>
                                Área do ambiente
                                <span className={styles.columnsRadio}>
                                  <input
                                    type="radio"
                                    value="LxP"
                                    name={`n` + index}
                                    defaultChecked={true}
                                    onChange={(e) =>
                                      handleTypeFromIndex(e, index)
                                    }
                                  />
                                  <span
                                    className={styles.margin_text_input_area}
                                  >
                                    L x P
                                  </span>
                                  <input
                                    type="radio"
                                    value="m2"
                                    name={`n` + index}
                                    onChange={(e) =>
                                      handleTypeFromIndex(e, index)
                                    }
                                  />
                                  <span
                                    className={styles.margin_text_input_area}
                                  >
                                    m²
                                  </span>
                                </span>
                              </div>
                            </label>
                          </div>
                          {res.type === "m2" && (
                            <div className={styles.columns2}>
                              {/* <div className={styles.columns55}> */}
                              <input
                                value={res?.area}
                                onChange={(e) => {
                                  const nreResult = produce(
                                    result,
                                    (draftState) => {
                                      draftState[index].area = e.target.value;
                                    }
                                  );
                                  setResult(nreResult);
                                }}
                                style={
                                  isMobile ? { width: "90%", height: 40 } : {}
                                }
                                id="area_ambi"
                                type="number"
                                name="area_ambi"
                                placeholder="m²"
                              />
                              {/* </div> */}
                            </div>
                          )}
                          {res.type === "LxP" && (
                            <div className={styles.columns2}>
                              <input
                                value={res.dimension0}
                                onChange={(e) => {
                                  const nreResult = produce(
                                    result,
                                    (draftState) => {
                                      draftState[index].dimension0 =
                                        e.target.value;
                                      draftState[index].select = "Selecione";
                                      draftState[index].area = String(
                                        Number(e.target.value) *
                                          Number(draftState[index].dimension1)
                                      );
                                    }
                                  );
                                  setResult(nreResult);
                                }}
                                style={
                                  isMobile ? { width: "45%", height: 40 } : {}
                                }
                                id="area_ambi"
                                type="number"
                                name="area_ambi"
                                placeholder="largura"
                              />
                              <input
                                value={res.dimension1}
                                onChange={(e) => {
                                  const nreResult = produce(
                                    result,
                                    (draftState) => {
                                      draftState[index].dimension1 =
                                        e.target.value;
                                      draftState[index].select = "Selecione";
                                      draftState[index].area = String(
                                        Number(e.target.value) *
                                          Number(draftState[index].dimension0)
                                      );
                                    }
                                  );
                                  setResult(nreResult);
                                }}
                                style={
                                  isMobile ? { width: "45%", height: 40 } : {}
                                }
                                id="area_ambi1"
                                type="number"
                                name="area_ambi1"
                                placeholder="altura"
                              />
                            </div>
                          )}
                        </Col>

                        <Col md="auto" className="mt-4 mt-lg-0">
                          <label htmlFor="func" className="mb-3">
                            Funções
                          </label>
                          {/* <div className={styles.mt}> */}
                          <div className={styles.columnsRadio}>
                            <input
                              type="checkbox"
                              value="Wifi"
                              checked={onCheckFunction("Wifi", res)}
                              name="functions"
                              onClick={() =>
                                handleCheckValuFunction("Wifi", res, index)
                              }
                            />
                            <span className={styles.margin_text_input}>
                              Wifi
                            </span>
                            <div style={{ padding: 5 }} />
                            <input
                              type="checkbox"
                              value="Assist. de voz"
                              checked={onCheckFunction("Assist. de voz", res)}
                              onClick={() =>
                                handleCheckValuFunction(
                                  "Assist. de voz",
                                  res,
                                  index
                                )
                              }
                              name="functions1"
                            />
                            <span className={styles.margin_text_input}>
                              {" "}
                              Assist. de voz
                            </span>
                          </div>
                          {/* </div> */}
                        </Col>

                        <Col md="auto" className="mt-4 mt-lg-0">
                          <div className={styles.mviewrro}>
                            <div
                              style={
                                isMobile ? { width: "90%", height: 40 } : {}
                              }
                              onClick={() => onOpenModal(index)}
                              className={styles.viewcontntcwc}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${MenuIcon})`,
                                  backgroundSize: "cover",
                                  width: 25,
                                  height: 25,
                                }}
                              />
                              <span>
                                {Boolean(
                                  res.select && res.select !== "Selecione"
                                )
                                  ? res.select
                                  : "Selecione"}
                              </span>
                              <div style={{ width: 25, height: 25 }} />
                            </div>
                          </div>
                        </Col>
                        {Boolean(
                          res?.select &&
                            res.select.length !== 0 &&
                            res.select !== "Selecione"
                        ) && <Col md="auto" className="mt-4 mt-lg-0"></Col>}
                        {Boolean(
                          res?.iframe_url && isMobile && res?.select?.length
                        ) && (
                          <Col md="auto" className="mt-4 mt-lg-0">
                            <div className={styles.mviewrro}>
                              <div
                                style={
                                  isMobile
                                    ? {
                                        width: "90%",
                                        height: 40,
                                        backgroundColor: "#6e726d",
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                      }
                                    : {}
                                }
                                onClick={() => setShowIframe(true)}
                                className={styles.viewcontntcwc}
                              >
                                <div
                                  style={{
                                    backgroundImage: `url(${ViewIcon})`,
                                    backgroundSize: "cover",
                                    width: 25,
                                    height: 25,
                                  }}
                                />
                                <span style={{ color: "white", fontSize: 12 }}>
                                  {"VISUALIZE NO SEU AMBIENTE"}
                                </span>
                                <div style={{ width: 25, height: 25 }} />
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Container>
                  </div>
                  {res.iframe_url && showIframe && (
                    <div className={`${styles.container_modal_iframe}`}>
                      <div
                        className={`${styles.btn_close_iframe_VA}`}
                        onClick={() => setShowIframe(false)}
                      >
                        <span
                          className="fas fa-times"
                          aria-hidden="true"
                        ></span>
                      </div>
                      <iframe
                        src={res.iframe_url}
                        allow="vr; xr; accelerometer; magnetometer; gyroscope; camera;"
                        frameBorder={0}
                        marginHeight={0}
                        marginWidth={0}
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>

        {ambiente !== 0 && (
          <div className={styles.view}>
            {/* <div className={styles.container} /> */}
            <hr />

            <div className={styles.container}>
              <div className={styles.rowwEnd}>
                {getValidation() && (
                  <div
                    className={styles.buttonss}
                    onClick={() => handleNextPage(imovelType)}
                  >
                    <div className="d-flex align-items-center">
                      <span className="d-flex align-items-center">
                        CALCULAR{" "}
                        <div className="ml-1 align-items-center">
                          <Media>
                            <img
                              width={13}
                              height={13}
                              src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fchevron_white_right.svg?alt=media&token=f9aeeebf-888e-4313-a196-e4fd7bdecf7d"
                              alt="icone avançar"
                            />
                          </Media>
                        </div>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: Boolean(indexNumberModalVisible && isMobile)
            ? "flex"
            : "none",
        }}
        className={styles.modalView}
      >
        <MODAL_CONTENT />
      </div>

      <div
        style={{
          display: Boolean(indexNumberModalVisible && !isMobile)
            ? "flex"
            : "none",
        }}
        className={styles.modalView}
      >
        <div className={styles.desktopViewModal}>
          <MODAL_CONTENT />
        </div>
      </div>
    </div>
  );
};
export default Simulator;
