import React, { createContext, useState, useContext, useEffect } from "react";
import { useWindowDimensions } from '../hooks/useDimension'
type ContextType = {
    isMobile: boolean;
    setIsMobile: (value: boolean) => void;
};

const ContextMain = createContext<ContextType>({
    isMobile: false,
    setIsMobile: (value: boolean) => { },
});

const ProviderScreen: React.FC = ({ children }) => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    useEffect(() => {
        if (width <= 800) {
            if (!isMobile) {
                setIsMobile(true)
            }
        } else {
            if (isMobile) {
                setIsMobile(false)
            }
        }
    }, [width, isMobile])
    return (
        <ContextMain.Provider
            value={{
                isMobile, setIsMobile
            }}
        >
            {children}
        </ContextMain.Provider>
    );
};
export default ProviderScreen;

export function useScreen() {
    const info: ContextType = useContext(ContextMain);
    const { isMobile, setIsMobile } = info;
    return { isMobile, setIsMobile };
}