import React from 'react';
import styles from './styles.module.scss'
import Container from 'react-bootstrap/Container'
import {Row, Col} from 'react-bootstrap'
import Cards from '../../componets/Cards'
import AdiasLogo from '../../assets/partners/a_dias.png'
import ArClubeLogo from '../../assets/partners/arclube.png'
import CentralArLogo from '../../assets/partners/central_ar.png'
import ClimaRioLogo from '../../assets/partners/climario.png'
import DufrioLogo from '../../assets/partners/dufrio.png'
import FrioPecasLogo from '../../assets/partners/friopecas.png'
import InfoArLogo from '../../assets/partners/webcontinental.png'
import LeverosLogo from '../../assets/partners/leveros.png'
import MagnoLogo from '../../assets/partners/magno.png'
import PoloArLogo from '../../assets/partners/poloar.png'
import STRLogo from '../../assets/partners/str.png'

interface ArrayPartner {
    logo: any;
    name: string;
    link: string;
}
const Products: React.FC = () =>{
    const arrayPartner: ArrayPartner[] = [
        {
            link:'https://www.adias.com.br/',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fa_dias.png?alt=media&token=aa852d38-3180-4b18-8ff5-af6fbed4b55e',
            name:'Adias'
        },
        {
            link:'https://www.arclube.com.br/marcas/lg',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Farclube.png?alt=media&token=9b8fb1f9-0c4c-4666-bcc5-7663b632d350',
            name:'ArClube'
        },
        {
            link:'https://www.centralar.com.br/arcondicionado/multisplit/c/MULTI_SPLIT?q=%3Arelevance%3Abrand%3ALG&text=#',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fcentral_ar.png?alt=media&token=b8c4ddee-6371-4e53-876a-389a89ce97eb',
            name:'CentralAr'
        },
        {
            link:'https://www.climario.com.br/ar-condicionado/ar-condicionado-multi-split/lg?PS=18',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fclimario.png?alt=media&token=3ec89e15-bf8d-4add-9339-86e044f514d5',
            name:'ClimaRio'
        },
        {
            link:'https://www.dufrio.com.br/ar-condicionado/multi-split/?marca=74',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fdufrio.png?alt=media&token=0f8bef23-aac1-4e45-9551-18749f78f733',
            name:'Dufrio'
        },
        {
            link:'https://www.friopecas.com.br/ar-condicionado/ar-condicionado-multi-split/lg',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Ffriopecas.png?alt=media&token=3f2a106d-538f-40de-9ac7-7086f5b11d2f',
            name:'Friopeças'
        },
        {
            link:'https://www.webcontinental.com.br/searchresults?&&N=2032478014+1631563888&Nrpp=12&searchType=guided&type=search',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fwebcontinental.png?alt=media&token=2261d71d-11ba-4c94-9f52-7694b960a515',
            name:'Infoar'
        },
        {
            link:'https://www.leveros.com.br/ar-condicionado/multi-split/lg?O=OrderByTopSaleDESC&PS=12',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fleveros.png?alt=media&token=6196f84d-0e83-467c-a80c-7804b454df96',
            name:'Leveros'
        },
        {
            link:'https://www.magnodistribuidora.com.br/loja/catalogo.php?loja=469073&categoria=427&palavra_busca=&filtrar_marca=LG&order=5&pg=1',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fmagno.png?alt=media&token=3b7afb9f-d8eb-43ff-8991-ca0a29a854b6',
            name:'Magno'
        },
        {
            link:'https://www.poloar.com.br/ar-condicionado/multi-split/lg?map=c,c,b',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fpoloar.png?alt=media&token=f6705ec6-90c4-4015-b060-f115804b48a3',
            name:'PoloAr'
        },
        {
            link:'https://www.strar.com.br/ar-condicionado/multi-split/lg?PS=12',
            logo: 'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Flg%2Fpartners%2Fstr.png?alt=media&token=7461b63e-7620-4d58-812d-b26dcf5f0d24',
            name:'STR'
        },
    ]
return (
        <> 
            <div className={styles.bg_Partners}>
            <Container>

                <h1 className={styles.PrtnersTitle}>Onde Comprar</h1>

                <Row className="mt-5">
                    {arrayPartner.map((res: ArrayPartner, index: number)=>{
                        return(
                            <Col key={index} sm={6} lg={3} className={styles.card_partners}>
                              <Cards link={res.link} logo={res.logo} name={res.name} />
                           </Col> 
                        )
                    })}
   
                </Row>       
            
            
            </Container>
        </div>
        </>
    )
}
export default Products;