import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss'
import Results from '../../componets/Results'
import OtherResults from '../../componets/OtherResults'
import Container from 'react-bootstrap/Container'
import { useHistory } from "react-router-dom";
import { UserResult } from '../../context/result';

interface Con {
    name: string;
    btuCode: number;
    kW: number;
    capacity: string;
    input: number;
}
const Condesadoras: Con[] = [
    {
        name: 'Bi Split',
        input: 2,
        btuCode: 18000,
        kW: 5.27,
        capacity: 'A2UW18GFA2'
    },
    {
        name: 'Tri Split',
        btuCode: 24000,
        input: 3,
        kW: 7.03,
        capacity: 'A3UW24GFA2'
    },
    {
        name: 'Tetra Split',
        input: 4,
        btuCode: 30000,
        kW: 8.79,
        capacity: 'A5UW30GFA2'
    },
    {
        name: 'Penta Split ',
        input: 5,
        btuCode: 36000,
        kW: 10.5,
        capacity: 'A5UW36GFA2'
    },
    {
        name: 'Penta Split ',
        btuCode: 48000,
        input: 5,
        kW: 14.06,
        capacity: 'A5UW48GFA1'
    }

]
const Products: React.FC = () => {
    const history = useHistory()
    const [result, setResult] = useState<Con[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [evaporadoras, setEvaporadoras] =useState<UserResult>({} as any)
    const load = () => {
        const value = localStorage.getItem('@userResult')
        if (value) {
            const val: UserResult = JSON.parse(value)
            setEvaporadoras(val)
            const cond = Condesadoras.filter(e => Number(Number(Number(val.btuTotal) / Number(e.btuCode)) * 100) > 50)
            const conde = cond.filter(e=> e.input >= val.result.length)
            if (val.imovelType === 'company') {
                const resss = conde.filter(e => Number(Number(Number(val.btuTotal) / Number(e.btuCode)) * 100) <= 150)
                setResult(resss)
            } else {
                setResult(conde)
            }

            console.log('conde', conde)
            setLoaded(true)
        } else {
            history.replace('/lg')
        }
    }
    useEffect(() => {
        load()
    }, []);

    if (!loaded) {
        return null
    }
    return (
        <>
            <Container>

                <h1 className={styles.textSimulator}>Simulador de Capacidade</h1>
                <p className={styles.text}>Descubra o ar-condicionado ideal para você e quanto poderá economizar.</p>
                <hr className={styles.hr_simulator} />



            </Container>

            <div className={styles.body_view}>

                <Results evaporadoras={evaporadoras} data={result} />

                {/* <OtherResults /> */}

            </div>
        </>
    )
}
export default Products;