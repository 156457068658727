import React from 'react';
import styles from './styles.module.scss'
import Container from 'react-bootstrap/Container'
import { useScreen } from '../../context/screen'
const Footer: React.FC = () => {
    const { isMobile } = useScreen()
    return (
        <>
            { isMobile && <>
                <div className='d-flex text-center align-items-center justify-content-center'>
                    <p style={{alignItems:'center', textAlign:'center', width:'90%'}} className={styles.saibamais}>
                        Saiba mais sobre o sistema do ar condicionado Multi Split da LG. <b><span><a href="https://www.lg.com/br/business/multi-split-ar-condicionado" target="_blank">Clique aqui</a></span></b>
                    </p>

                </div>  <br /></>}

            <div className={styles.footer}>
                <Container>
                    <p className={styles.span_footer}>
                        Copyright © 2009-2020 LG Eletronics. Todos os direitos reservados. Esta é a página oficial da LG Eletronics. Se você deseja se conectar à LG Gorp. ou a outras afiliais da LG, clique aqui
                </p>
                </Container>
            </div>
        </>
    )
}

export default Footer;