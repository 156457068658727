import React from 'react'
// import styles from './styles.module.scss'
import Header from '../Header'
import Footer from '../Footer'
const Container: React.FC = ({ children }) => {

    return (
        <>
            <Header />
            <div>
                {children}
            </div>
            <Footer />

        </>
    )
}
export default Container;