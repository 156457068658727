import React from 'react';
import ReactDOM from 'react-dom';
import RouterMain from './routes';
import { ToastProvider } from 'react-toast-notifications';
import './css/index.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import ProviderScreen from './context/screen'
import ProviderResult from './context/result'
ReactDOM.render(
  <ToastProvider
    autoDismiss
    autoDismissTimeout={6000}>
    <ProviderResult>
      <ProviderScreen>
        <RouterMain />
      </ProviderScreen>
    </ProviderResult>
  </ToastProvider>
  ,
  document.getElementById('root')
);


