import React from 'react';
import styles from './styles.module.scss'
import { Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

const ButtonsResults: React.FC = () => {

    const history = useHistory()
    const handleNextPage = () => {
        history.push(`/lg/Partners`)
        window.scrollTo(0, 0)
    }

    return (
        <>

            <Row  className='mt-4 text-center align-items-center'>
                <Col sm={6} className='p-1 d-flex justify-content-center'>
                    <div className={styles.button} onClick={() => handleNextPage()}>
                        Onde comprar
                    </div>
                </Col>

                <Col sm={6} className='p-1 d-flex justify-content-center'>
                    <div className={styles.button} onClick={() => window.open('https://www.lg.com/br/business/multi-split-ar-condicionado')}>
                        Saiba mais
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ButtonsResults;